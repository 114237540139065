<template>
    <div class="auth-layout">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'AuthLayout',
};
</script>

<style scoped>
.auth-layout {
    background-color: var(--tertiary);
    display: grid;
    place-items: center;
    height: 100vh;
    width: 100vw;
}
</style>
